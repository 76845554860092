<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-layout justify-center wrap>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="blue"
        spinner="mini-spinner"
      />
      <v-flex xs12 v-if="purchaseItem" align-self-center>
        <v-card outlined elevation="2" class="mx-auto pa-6">
          <!----------------------filters--------------------------------->
           <v-layout wrap>
            <v-flex xs12 sm9 md10 class="text-left nsbold"
                style="font-weight: 700; font-size: 20px">
Purchase History
            </v-flex>
            <v-flex xs12 sm2 pa-2 text-right>
            <download-excel :data="myData" :fields="json_fields">
                   <v-btn
                      width="155px"
                      dark
                      style="text-transform: none"
                      color="#766BC0"
                     class="hidden-xs-only"
                      >Download Excel</v-btn
                    >
                     <v-btn
                      width="155px"
                      dark
                      block
                      class="hidden-sm-and-up"
                      style="text-transform: none"
                      color="#766BC0"
                      >Download Excel</v-btn
                    >
                  </download-excel>
          </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex
              px-2
              pl-lg-4
              pt-lg-6
              pt-4
              xs12 sm3 lg3
              class="text-left nsregular"
              > <v-select
                    :items="HostelList"
                    v-model="hostel"
                    label="Hostel"
                    item-text="hostelName"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                  ></v-select>
            </v-flex>
            <v-flex xs12 sm3 lg3 px-2 pl-lg-4 pt-lg-6 pt-4>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 lg3 px-2 pl-lg-4 pt-lg-6 pt-4>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    outlined
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 lg3 px-2 pl-lg-4 pt-lg-6 pt-4>
              <v-text-field
                v-model="keyword"
                dense
                label="Search Item"
                outlined
                clearable
              ></v-text-field>
            </v-flex>
          </v-layout>
          <!------------------------------------------------------->
          <v-layout wrap>
            <v-flex xs12 pa-4>
              <v-card tile outlined flat>
                <v-layout wrap justify-center>
                  <v-flex pa-2 xs12 align-self-center text-center>
                    <span class="nsbold" style="border-bottom: 1px solid grey"
                      >Summary</span
                    >
                  </v-flex>
                  <v-flex
                    xs12
                    sm4
                    align-self-center
                    text-center
                    pa-2
                    v-for="(sub, s) in summary"
                    :key="s"
                  >
                    <span v-if="sub._id">
                      {{ sub._id.name }} - {{ sub.count }} 
                    </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 pa-2 v-if="purchaseItem">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">No.</th>
                      <th class="text-left">Purchase Date</th>
                      <th class="text-left">Bill No.</th>
                      <th class="text-left">Shop Name</th>
                      <th class="text-left">Name</th>
                      <th class="text-left">Qty.</th>
                      <th class="text-left">Price(Rs)</th>
                      <th class="text-left">Total(Rs)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in purchaseItem" :key="i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ value.create_date.slice(0, 10) }}</td>
                      <td>
                        <span v-if="value.purchaseId">
                          {{ value.purchaseId.billNo }}
                        </span>
                        <span v-else>NA</span>
                      </td>

                      <td>
                        <span v-if="value.purchaseId">
                          <span v-if="value.purchaseId.shopName">
                            {{ value.purchaseId.shopName }}
                          </span>
                        </span>
                        <span v-else>NA</span>
                      </td>
                      <td><span v-if="value.assetid">{{ value.assetid.name }}</span>
                      <span v-else>-</span></td>
                      <td>
                        {{ value.quantity }}
                      </td>

                      <td>{{ value.price }}</td>
                      <td>{{ value.quantity * value.price }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
          </v-layout>
        </v-card>
        <v-flex xs12 pt-4>
        <v-pagination color="#766BC0" small v-model="page" :length="Pagelength"> </v-pagination>
        </v-flex>
     <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      test: null,
      msg: null,
      showsnackbar: false,
      menu: false,
      menu2: false,
      keyword: null,
      ServerError: false,
      appLoading: false,
      page: 1,
      Pagelength: 0,
      purchaseItem: [],
      HostelList: [],
      hostel: null,
      hostelName: null,
      summary: null,
       // download excel
      myData: [],
      excel: {
        purdate: "",
        bill: "",
        shop: "",
        name: "",
        qty: "",
        price: "",
        total: "",
      },
      json_fields: {
        Purchase_Date: "purdate",
        Bill_No: "bill",
        Shop_Name: "shop",
        Name: "name",
        Quantity: "qty",
        Price: "price",
        Total: "total",
      },
      //
      //   fromDate: "",
      //   toDate: "",
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  mounted() {
     this.gethostel();
     if (this.fromDate) {
      var dt =new Date()
      
      var day=dt.getDate()
      dt.setDate(dt.getDate() -  day+1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    this.getData();
    this.getExcel();

    // this.getinnerData();

    // this.getType()
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    hostel() {
      this.getData();
    this.getExcel();

    },
    fromDate() {
      // this.getData();
      this.getData();
    this.getExcel();

    },
    toDate() {
      // this.getData();
      this.getData();
    this.getExcel();

    },

    // from() {

    //   this.pendingData();
    // },
    // to() {

    //   this.pendingData();
    // },

    keyword() {
      // this.getData();
      this.getData();
    this.getExcel();

    },
    cvalue() {
      // this.getData();
      this.getData();
    this.getExcel();

    },
  },
  methods: {
     gethostel() {
      axios({
        method: "get",
        url: "/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getData() {
      this.appLoading = true;
      this.purchaseItem=null
      axios({
        method: "POST",
        url: "/assetpurchase/report",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          page: this.page,
          limit: 20,
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
          hostelId :this.hostel,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.purchaseItem = response.data.data;
            this.summary = response.data.summary;
            // this.shopDetails = response.data.purchaseDetails;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    getExcel() {
      this.appLoading = true;
      this.myData = [];
      axios({
        method: "POST",
        url: "/assetpurchase/report",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          keyword: this.keyword,
          fromDate: this.fromDate,
          toDate: this.toDate,
          hostelId :this.hostel,
        },
      })
        .then((response) => {
          this.appLoading = false;
      this.myData = [];
          if (response.data.status == true) {
            // this.purchaseItem = response.data.data;
            // this.summary = response.data.summary;
            // this.shopDetails = response.data.purchaseDetails;
             //excel
             for (let i = 0; i < response.data.data.length; i++) {
              
              if (response.data.data[i].create_date) {
                this.excel.purdate = this.formatDate(response.data.data[i].create_date);
              }
              if (response.data.data[i].purchaseId.billNo) {
                this.excel.bill = response.data.data[i].purchaseId.billNo;
              }
              if (response.data.data[i].purchaseId.shopName) {
                this.excel.shop = response.data.data[i].purchaseId.shopName;
              }
              if (response.data.data[i].assetid.name) {
                this.excel.name = response.data.data[i].assetid.name;
              }
              if (response.data.data[i].quantity) {
                this.excel.qty = response.data.data[i].quantity;
              }
              if (response.data.data[i].price) {
                this.excel.price = response.data.data[i].price;
              }
              if (response.data.data[i].totalAmount) {
                this.excel.total = response.data.data[i].totalAmount;
              }
              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};
            }
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>