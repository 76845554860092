<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 sm8 md8 lg12 pa-6>
          <v-tabs color="#766BC0" grow class="nsbold" style="font-size: 16px">
             <v-tab>Asset Report</v-tab>
            <v-tab>Bill History</v-tab>
            <v-tab-item>
              <adminAssetReport/>
            </v-tab-item>
            <v-tab-item>
              <adminAssetBills/>
            </v-tab-item>
            
          </v-tabs>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
import adminAssetReport from "./adminAssetReport"
import adminAssetBills from "./adminAssetBills"

export default {
  components : {
    adminAssetReport,
    adminAssetBills,
  },
  data() {
    return {
      page: 1,
      Pagelength: 0,
      keyword: null,
      ServerError: false,
      showsnackbar: false,
      msg: null,
      appLoading: false,
    };
  },
 
};
</script>