<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 >
      <v-layout wrap>
        <v-flex xs12>
          <v-card
            class="mx-auto elevation-1 pa-0 pa-sm-6"
            align="center"
            outlined
          >
          <v-layout wrap>
            <v-flex xs12 pa-2 class="text-left nsbold"
                style="font-weight: 700; font-size: 20px">
Bill History
            </v-flex>
          </v-layout>
            <v-layout wrap class="ma-sm-6 pa-4 pa-sm-0">
              <v-flex
                xs12 sm12 md3 lg3 pr-md-2 pb-2 pb-md-0
                class="text-left nsregular"
                >
                <v-select
                    :items="HostelList"
                    v-model="hostel"
                    label="Hostel"
                    item-text="hostelName"
                    item-value="_id"
                    clearable
                    outlined
                    hide-details
                    dense
                  ></v-select>
              </v-flex>
              <!----------------------filters--------------------------------->
              
                <v-flex xs12 md3 lg3 px-md-2 pb-2 pb-md-0>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="fromDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromDate"
                        label="From"
                        outlined
                        hide-details
                        readonly
                        dense
                        clearable
                        class="rounded-0"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      no-title
                      scrollable
                      @change="$refs.menu.save(fromDate)"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md3 lg3 px-md-2 pb-2 pb-md-0>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="toDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="toDate"
                        label="To"
                        hide-details
                        readonly
                        outlined
                        dense
                        clearable
                        class="rounded-0"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toDate"
                      no-title
                      scrollable
                      @change="$refs.menu2.save(toDate)"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md3 lg3 pl-md-2 pb-2 pb-md-0>
                  <v-text-field
                    v-model="keyword"
                    dense
                    label="Search Bill/ Shop"
                    outlined
                    hide-details
                    clearable
                  ></v-text-field>
                </v-flex>
              
              <!------------------------------------------------------->
              <!-- <v-flex xs12 lg2>
                  <v-btn x-small width="100px" dark color="grey" class="py-4" @click="adddialogue = true">
                  <v-icon x-small 
                    >mdi-plus</v-icon
                  >
                  <span
                    class="text-left nsregular"
                    style="font-weight: 400; font-size: 12px"
                    >Add</span
                  ></v-btn>
              </v-flex> -->
            </v-layout>
            <!-- <v-layout py-3>
              <v-flex
                xs1
                class="text-left nsbold"
                style="font-weight: 700; font-size: 18px"
              >
                <span>Id</span>
              </v-flex>
              <v-flex
                xs5
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Name</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Type</span>
              </v-flex>
            </v-layout> -->
            <!------------------------------------------->
            <v-card elevation="0" class="rounded-xl ma-sm-6">
              <v-layout wrap>
                <v-flex xs12>
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(item, i) in stock" :key="i">
                      <v-expansion-panel-header
                        @click="getinnerData(item._id)"
                        :style="i % 2 == 0 ? 'background-color:#eeeeee' : ''"
                      >
                        <v-layout wrap>
                          <v-flex text-left xs5 px-1>
                            <span class="nsregular" style="font-size: 16px"
                              >Bill.No: {{ item.billNo }}
                            </span>
                          </v-flex>
                          <v-flex text-right xs6 px-1>
                            <span class="nsregular" style="font-size: 15px">
                              {{ formatDate(item.purchaseDate) }}
                            </span>
                          </v-flex>
                          <v-flex text-right xs1 px-2>
                            <v-icon
                          style="cursor: pointer"
                          @click="(deldialoge = true),  (curId1 = item._id)"
                          >mdi-delete-outline</v-icon
                        >
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-layout wrap>
                          <v-flex xs12 md6 lg6>
                            <v-layout wrap v-if="shopDetails">
                              <v-flex
                                xs12
                                v-if="shopDetails.billNo"
                                text-left
                                class="nsregular"
                                style="font-size: 16px"
                                pt-4
                                pb-2
                              >
                                <span>
                                  Shop Name :
                                  {{ shopDetails.shopName }}
                                </span>
                              </v-flex>
                              <v-flex
                                xs12
                                v-if="shopDetails.billNo"
                                text-left
                                class="nsregular"
                                style="font-size: 16px"
                                pb-2
                              >
                                <span>
                                  Total Amount (Rs.):
                                  {{ shopDetails.totalAmount }}
                                </span>
                              </v-flex>
                              <v-flex
                                xs12
                                v-if="shopDetails.billNo"
                                text-left
                                class="nsregular"
                                style="font-size: 16px"
                                pb-2
                              >
                                <span>
                                  Discount:
                                  <span v-if="shopDetails.discount">
                                    {{ shopDetails.discount }}
                                  </span>
                                  <span v-else>NA</span>
                                </span>
                              </v-flex>
                              <v-flex
                                xs12
                                v-if="shopDetails.amountPaid"
                                text-left
                                class="nsregular"
                                style="font-size: 16px"
                                pb-2
                              >
                                <span>
                                  Total Amount with Discount (Rs.) :
                                  {{ shopDetails.amountPaid }}
                                </span>
                              </v-flex>

                              <v-flex
                                xs12
                                v-if="shopDetails.payableAmount"
                                text-left
                                class="nsregular"
                                style="font-size: 16px"
                                pb-2
                              >
                                <span>
                                  Total Amount paid to Retailer (Rs.) :
                                  {{ shopDetails.payableAmount }}
                                </span>
                              </v-flex>
                              <v-flex xs12 v-if="shopDetails.photos.length>0">
                                  <v-btn color="#766BC0" block dark dense @click="showImage(shopDetails.photos)">
                                     <span
                          class="text-left nsregular"
                          style="font-weight: 400; font-size: 16px; text-transform: none;"
                          >View bill</span
                        >
                                  </v-btn>
                              </v-flex >
                              <!-- <v-flex xs12 v-if="shopDetails.billNo" text-left class="nsregular" style="font-size: 18px" pb-2>
                                    <span>
                                        Shop Name:{{shopDetails.shopName}}
                                    </span>
                                </v-flex> -->
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 md6 lg6 v-if="purchaseItem">
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">No.</th>
                                    <th class="text-left">Name</th>
                                    <th class="text-left">Qty.</th>
                                    <th class="text-left">Price(Rs)</th>
                                    <th class="text-left">Total(Rs)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(value, i) in purchaseItem"
                                    :key="i"
                                  >
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ value.assetid.name }}</td>
                                    <td>{{ value.quantity }}</td>

                                    <td>{{ value.price }}</td>

                                    <td>{{ value.quantity * value.price }}</td>

                                    <!-- <td>{{ value.studentid[0].admissionNo }}</td>
                                <td>{{ value.clothid.clothType.name }}</td>
                                <td>{{formatDate(value.INdate)}}</td> -->
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
                <v-flex xs12 py-2>
                  <v-pagination
                    color="#766BC0"
                    small
                    v-model="page"
                    :length="test"
                  >
                  </v-pagination>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap justify-center>
              <v-flex xs12 v-if="mylist" px-6 pt-2>
                <v-card round style="background-color:#EEEEEE">
                </v-card>

           
                <v-card v-for="(item, i) in pending" :key="i" elevation="0">
    
                  <v-layout wrap pa-2 class="nsregular" style="font-size:16px;">
                    <v-flex xs12 pa-6 v-for="(sub,s) in item.data" :key="s">
                      {{ sub.INdate }}<br/>{{sub.clothid.QRcode}}<br/>{{sub.clothid.clothType.name}}
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                </v-card>
              </v-flex>
            </v-layout> -->
            </v-card>

            <!--------------------------------------------------->
            <!-- <v-layout
              v-for="(item, i) in stock"
              :key="i"
              py-2
              :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
            >
              <v-flex
                xs1
                class="text-left regular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ i + 1 }}</span>
              </v-flex>
              <v-flex
                xs2
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.itemName.itemName }}</span>
              </v-flex>
              <v-flex
                xs2
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ formatDate(item.quantity) }}</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.itemName.update_date }}</span>
              </v-flex>
              <v-flex xs1 v-if="item">
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(curItem = item), (editdialogue = true)"
                    >mdi-circle-edit-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-flex xs1>
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(dialoge = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-divider></v-divider>
            </v-layout> -->
          </v-card>
        </v-flex>
      </v-layout>
   <!-- <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="grey" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4 black--text"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <v-dialog width="400px" v-model="deldialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4 black--text"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deldialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteBill()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="400px" v-model="editdialogue">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Edit Item </v-toolbar-title>
          </v-toolbar>

          <v-layout wrap v-if="curItem">
            <v-flex xs12 px-4 py-2>
              <v-text-field
                v-model="curItem.itemName"
                outlined
                dense
              ></v-text-field>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
              <v-text-field
                v-model="curItem.itemType"
                outlined
                dense
                label="Item Type"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="editdialogue = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="editCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="imageDialog" width="500px">
        <v-card width="500px">
           <v-layout wrap>
             <v-flex xs12 pa-1 v-for="(item ,i) in billImage  " :key="i" >
            

            <v-img height="400px" contain :src="mediaURL + item">

            </v-img>
             </v-flex>
           </v-layout>
           </v-card>
      </v-dialog>

      <!-- <v-dialog width="400px" v-model="adddialogue">
        <v-card width="400px">
          <v-toolbar dark color="grey" dense flat class="body-2">
            <v-toolbar-title> Add Item </v-toolbar-title>
          </v-toolbar>

          <v-layout wrap>
            <v-flex xs12 px-4 py-2>
              <v-text-field autofocus v-model="name" outlined dense></v-text-field>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
             
            <v-select
              :items="typeList"
              v-model="typee"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-value="_id"
              outlined
              @input="search()"
              dense
            ></v-select>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
            <v-select
              :items="quantitytypes"
              v-model="quantitytype"
              clearable
              label="Select Type"
              outlined
              dense
            ></v-select>
            </v-flex>
            <v-flex xs12 px-4 pb-2 v-if="quantitytype=='Weight'">
            <v-select
              :items="weightType"
              v-model="productType"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-id="_id"
              outlined
              dense
            ></v-select>
            </v-flex>
            <v-flex xs12 px-4 pb-2 v-if="quantitytype=='Liters'">
            <v-select
              :items="litersType"
              v-model="productType"
              clearable
              label="Type"
              item-text="itemCategoryName"
              item-id="_id"
              outlined
              dense
            ></v-select>
            </v-flex>
          </v-layout>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="adddialogue = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="addCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      imageDialog:false,
      billImage:[],
      test: 0,
      HostelList: [],
      hostel: null,
      hostelName: null,
      student: [],
      stock: [],
      menu2: null,
      menu: null,
      keyword: null,
      shopDetails: null,
      dialoge: false,
      editdialogue: false,
      adddialogue: false,
      name: null,
      curId: null,
      curItem: null,
      showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
      typeList: null,
      typee: "",
      page: 1,
      curId1: null,
      deldialoge: false,

      Pagelength: 0,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      quantitytypes: ["Count", "Weight", "Liters"],
      quantitytype: null,
      productType: "",
      purchaseItem: null,
      weightType: ["Gram", "Kilo gram"],
      litersType: ["Milli Liter", "Liter"],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  watch: {
    page() {
      this.getData();
    },
    fromDate() {
      // this.getData();
      this.getData();
    },
    toDate() {
      // this.getData();
      this.getData();
    },

    keyword() {
      // this.getData();
      this.getData();
    },
     hostel() {
      this.getData();
    },
  },
  mounted() {
    this.gethostel();

     if (this.fromDate) {
      var dt =new Date()
      
      // var day=dt.getDate()
      dt.setDate(dt.getDate() -  31);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    this.getData();
    // this.getinnerData();

    // this.getType()
  },
  methods: {
 gethostel() {
      axios({
        method: "get",
        url: "/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
            this.hostelName = this.HostelList[0]._id;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
   showImage(item)
   {
  this.billImage = item;
  this.imageDialog =true;
   },
 deleteBill() {
      axios({
        url: "/assetpurchase/bill/delete",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          assetId: this.curId1,
        },
      })
        .then((response) => {
          this.delete = false;
          this.deldialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/asset/purchase/getlist/v1",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
          hostelId :this.hostel,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.stock = response.data.data;
            this.test = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //inner expansion
    getinnerData(id) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/asset/purchase/details",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          billId: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.shopDetails = response.data.data;
            this.purchaseItem = response.data.purchaseitems;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/item/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    
    //edit button
    editCategory() {
      axios({
        url: "/item/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          itemName: this.curItem.itemName,
          itemType: this.curItem.itemType,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //date
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year 
        // +
        // " , " +
        // hours +
        // ":" +
        // minutes +
        // " " +
        // ampm;

      return strTime;
    },
  },
};
</script>